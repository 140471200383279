import React from 'react'
import Grid from '@mui/material/Grid'
import SelectButton from './SelectButton'
import Drawer from '@mui/material/Drawer'
import Typography from '@mui/material/Typography'
import FastfoodIcon from '@mui/icons-material/Fastfood';
import MicrowaveIcon from '@mui/icons-material/Microwave';

type Prop = {
  iconType?: 'microwave' | 'food',
  title?: string,
  values: number[],
  unit: string,
  onSelect: (value: number) => void,
  drawer: boolean,
  onClose: () => void,
}

type State = {
  drawer: boolean,
}

class SelectButtonTemplate extends React.Component<Prop, State> {
  constructor(props: Prop) {
    super(props)
    this.state = {
      drawer: this.props.drawer,
    }
    this.onSelectButtonClick = this.onSelectButtonClick.bind(this)
  }

  render() {
    // 値ごとのボタンを生成
    const buttons = this.props.values.map((value: number) =>
      <Grid item xs={6} key={value}>
        <SelectButton
          value={value}
          unit={this.props.unit}
          onClick={this.onSelectButtonClick}
        />
      </Grid>
    )

    return (
      <Drawer
        anchor="bottom"
        open={this.state.drawer}
        onClose={this.props.onClose}
      >
        <Grid container padding={1} spacing={1} pb={3}>
          {this._title()}
          {buttons}
        </Grid>
      </Drawer>
    )
  }

  _title() {
    if (!this.props.title) {
      return null
    }

    let RenderIcon
    let IconStyle: React.CSSProperties = {}

    switch (this.props.iconType) {
      case 'microwave':
        RenderIcon = MicrowaveIcon
        IconStyle = { position: 'relative', top: '7px' }
        break

      case 'food':
        RenderIcon = FastfoodIcon
        IconStyle = { position: 'relative', top: '7px' }
        break

      default:
        // props.iconType が何も該当しない場合のデフォルト
        RenderIcon = MicrowaveIcon
        IconStyle = {}
        break
    }

    return (
      <Grid item xs={12}>
        <Typography variant="subtitle2" mt={0.5}>
          <RenderIcon style={IconStyle} />
          {this.props.title}
        </Typography>
      </Grid>
    )
  }

  onSelectButtonClick(value: number) {
    this.props.onSelect(value)
  }

  componentDidUpdate(prevProps: Readonly<Prop>) {
    if (prevProps.drawer !== this.props.drawer) {
      this.setState({ drawer: this.props.drawer });
    }
  }
}

export default SelectButtonTemplate
