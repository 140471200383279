import React from 'react'
import Button from '@mui/material/Button'
import ReplayIcon from '@mui/icons-material/Replay';

type Props = {
  onClick?: () => void
}
class ResetButton extends React.Component<Props> {
  render() {
    return (
      <Button
        startIcon={<ReplayIcon />}
        onClick={this.props.onClick}
        variant="contained"
      >
        スタート
      </Button>
    )
  }
}

export default ResetButton
